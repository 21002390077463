<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
    >
      <template #content>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              label="Nome da cidade"
              v-model="form.name"
              class="required"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="form.uf"
              :items="brazilStates"
              label="Estado:"
              class="required"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              hide-details
              label="Localização:"
              v-model="form.coordinate"
              hint="Latitude e longitude da cidade no Google Maps"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import miscellaneous from "@/config/miscellaneous";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    form: {},
    actionAfterOperation: null,
  }),
  computed: {
    brazilStates() {
      const states = miscellaneous.brazilStates.map((s) => ({
        text: s,
        value: s,
      }));
      return states;
    },
  },
  methods: {
    validate() {
      const errors = [];

      if (!this.form.name) errors.push("O campo NOME é obrigatório");

      if (!this.form.uf) errors.push("O campo ESTADO/UF é obrigatório");

      if (!this.form.coordinate)
        errors.push("O campo LOCALIZAÇÃO é obrigatório");

      return errors;
    },
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        await this.$axios.post(`/cities`, this.form);

        this.clearForm();

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(`/cities/${this.$route.params.id}`, this.form);

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async loadCityData() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(
          `/cities/${this.$route.params.id}`
        );

        this.setCityData(response.data);
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },
    setCityData(data) {
      if (data.coordinate)
        data.coordinate = `${data.coordinate.coordinates[1]},${data.coordinate.coordinates[0]}`;

      const cpData = { ...data };

      this.form = cpData;
    },
    afterOperation() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
    },
    clearForm() {
      this.form = {};
    },
  },
  created() {
    if (this.mod === "update") this.loadCityData();
  },
};
</script>

<style></style>
